/**
 * @generated SignedSource<<5db574aee5018a21c833858c3a72aab6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Entitlement = "brand_customization" | "slack_integration" | "ai_bot" | "ai_image_generation" | "ai_content_generation" | "ai_video_transcription" | "ai_subtitle" | "ai_inline_prompts" | "memberships" | "custom_profile_fields" | "multiple_zoom_accounts" | "quizzes" | "prerequisites" | "scorm" | "advanced_analytics" | "custom_domains" | "zapier_integration" | "bulk_invite" | "saml_sso" | "webhooks" | "whitelabel" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useHasEntitlementActiveOrganizationFragment$data = {
  readonly entitlements: ReadonlyArray<Entitlement>;
  readonly " $fragmentType": "useHasEntitlementActiveOrganizationFragment";
};
export type useHasEntitlementActiveOrganizationFragment$key = {
  readonly " $data"?: useHasEntitlementActiveOrganizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHasEntitlementActiveOrganizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useHasEntitlementActiveOrganizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitlements",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "118106e9186f493ad575d8af93f6b94c";

export default node;
