/**
 * @generated SignedSource<<29cac7d7d8a9d9b6b5d41d0fc8aba73b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollOptions_refreshResultsQuery$variables = {
  id: string;
};
export type PollOptions_refreshResultsQuery$data = {
  readonly question: {
    readonly " $fragmentSpreads": FragmentRefs<"PollOptionsFragment">;
  } | null;
};
export type PollOptions_refreshResultsQuery = {
  variables: PollOptions_refreshResultsQuery$variables;
  response: PollOptions_refreshResultsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PollOptions_refreshResultsQuery",
    "selections": [
      {
        "alias": "question",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PollOptionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PollOptions_refreshResultsQuery",
    "selections": [
      {
        "alias": "question",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "webFormRevisionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WebFormQuestionOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WebFormAnswerNodeConnection",
                "kind": "LinkedField",
                "name": "answers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebFormAnswerNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebFormAnswer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "webFormQuestionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "webFormSubmissionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "selectedOptions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "WebFormQuestion",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9930704582a3a4d856c297b4f47b90d5",
    "id": null,
    "metadata": {},
    "name": "PollOptions_refreshResultsQuery",
    "operationKind": "query",
    "text": "query PollOptions_refreshResultsQuery(\n  $id: ID!\n) {\n  question: node(id: $id) {\n    __typename\n    ...PollOptionsFragment\n    id\n  }\n}\n\nfragment PollOptionsFragment on WebFormQuestion {\n  id\n  type\n  webFormRevisionId\n  options {\n    id\n    label\n  }\n  answers {\n    edges {\n      node {\n        id\n        webFormQuestionId\n        webFormSubmissionId\n        selectedOptions\n        userId\n        user {\n          fullName\n          id\n          firstName\n          lastName\n          ...ProfileAvatarFragment\n          ...ProfileListModalListItemFragment\n        }\n      }\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileListModalListItemFragment on User {\n  id\n  firstName\n  lastName\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "6cdc1920707a6b0adc8d73c01988f034";

export default node;
