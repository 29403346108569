/**
 * @generated SignedSource<<00d6fdf5af7279c01511026cca27bbd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$data = {
  readonly submissions: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly webFormId: string;
        readonly webFormRevisionId: string;
        readonly completedAt: string | null;
        readonly durationSeconds: number | null;
        readonly hasAnswerKey: boolean;
        readonly hasPassed: boolean | null;
        readonly answers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly body: string | null;
              readonly selectedOptions: ReadonlyArray<string> | null;
              readonly webFormQuestionId: string;
              readonly isSkipped: boolean;
              readonly isCorrect: boolean | null;
              readonly result: {
                readonly correctIds: ReadonlyArray<string>;
                readonly incorrectSelections: ReadonlyArray<string>;
              } | null;
            };
          }>;
        };
        readonly result: {
          readonly score: number;
          readonly total: number;
          readonly percentage: number;
          readonly correctAnswers: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly incorrectAnswers: ReadonlyArray<{
            readonly id: string;
          }>;
        } | null;
        readonly user: {
          readonly id: string;
          readonly fullName: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
        };
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment";
};
export type webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$key = {
  readonly " $data"?: webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "submissions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "completedAfter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentUsageId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isComplete"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isFailed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isPassed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "membersOnly"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "percentageGreaterThanOrEqual"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "percentageLessThan"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "submissionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./usePaginatedSubmissionsPaginationFragment.graphql'),
      "identifierField": "id"
    }
  },
  "name": "webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment",
  "selections": [
    {
      "alias": "submissions",
      "args": [
        {
          "kind": "Variable",
          "name": "completedAfter",
          "variableName": "completedAfter"
        },
        {
          "kind": "Variable",
          "name": "contentUsageId",
          "variableName": "contentUsageId"
        },
        {
          "kind": "Variable",
          "name": "isComplete",
          "variableName": "isComplete"
        },
        {
          "kind": "Variable",
          "name": "isFailed",
          "variableName": "isFailed"
        },
        {
          "kind": "Variable",
          "name": "isPassed",
          "variableName": "isPassed"
        },
        {
          "kind": "Variable",
          "name": "membersOnly",
          "variableName": "membersOnly"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "percentageGreaterThanOrEqual",
          "variableName": "percentageGreaterThanOrEqual"
        },
        {
          "kind": "Variable",
          "name": "percentageLessThan",
          "variableName": "percentageLessThan"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "submissionIds",
          "variableName": "submissionIds"
        }
      ],
      "concreteType": "WebFormSubmissionNodeConnection",
      "kind": "LinkedField",
      "name": "__webFormQueryUtils_admin_submissions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormSubmissionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormSubmission",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormRevisionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "durationSeconds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasAnswerKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPassed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormAnswerNodeConnection",
                  "kind": "LinkedField",
                  "name": "answers",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswerNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormAnswer",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "body",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "selectedOptions",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "webFormQuestionId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isSkipped",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCorrect",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormAnswerResult",
                              "kind": "LinkedField",
                              "name": "result",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "correctIds",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "incorrectSelections",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormSubmissionResult",
                  "kind": "LinkedField",
                  "name": "result",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "score",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "percentage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswer",
                      "kind": "LinkedField",
                      "name": "correctAnswers",
                      "plural": true,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswer",
                      "kind": "LinkedField",
                      "name": "incorrectAnswers",
                      "plural": true,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileAvatarFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "WebFormRevision",
  "abstractKey": null
};
})();

(node as any).hash = "20bb471d9d7cc90dfa053afbaf7cbaab";

export default node;
